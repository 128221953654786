"use strict";

var _core = require("@uppy/core");

var _companionClient = require("@uppy/companion-client");

var _providerViews = require("@uppy/provider-views");

var _preact = require("preact");

const packageJson = {
  "version": "2.0.6"
};

const locale = require("./locale.js");

class Dropbox extends _core.UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = this.opts.id || 'Dropbox';

    _companionClient.Provider.initPlugin(this, opts);

    this.title = this.opts.title || 'Dropbox';

    this.icon = () => (0, _preact.h)("svg", {
      "aria-hidden": "true",
      focusable: "false",
      width: "32",
      height: "32",
      viewBox: "0 0 32 32"
    }, (0, _preact.h)("g", {
      fill: "none",
      fillRule: "evenodd"
    }, (0, _preact.h)("rect", {
      className: "uppy-ProviderIconBg",
      fill: "#0D2481",
      width: "32",
      height: "32",
      rx: "16"
    }), (0, _preact.h)("path", {
      d: "M11 8l5 3.185-5 3.186-5-3.186L11 8zm10 0l5 3.185-5 3.186-5-3.186L21 8zM6 17.556l5-3.185 5 3.185-5 3.186-5-3.186zm15-3.185l5 3.185-5 3.186-5-3.186 5-3.185zm-10 7.432l5-3.185 5 3.185-5 3.186-5-3.186z",
      fill: "#FFF",
      fillRule: "nonzero"
    })));

    this.provider = new _companionClient.Provider(uppy, {
      companionUrl: this.opts.companionUrl,
      companionHeaders: this.opts.companionHeaders,
      companionKeysParams: this.opts.companionKeysParams,
      companionCookiesRule: this.opts.companionCookiesRule,
      provider: 'dropbox',
      pluginId: this.id
    });
    this.defaultLocale = locale;
    this.i18nInit();
    this.title = this.i18n('pluginNameDropbox');
    this.onFirstRender = this.onFirstRender.bind(this);
    this.render = this.render.bind(this);
  }

  install() {
    this.view = new _providerViews.ProviderViews(this, {
      provider: this.provider
    });
    const {
      target
    } = this.opts;

    if (target) {
      this.mount(target, this);
    }
  }

  uninstall() {
    this.view.tearDown();
    this.unmount();
  }

  onFirstRender() {
    return Promise.all([this.provider.fetchPreAuthToken(), this.view.getFolder()]);
  }

  render(state) {
    return this.view.render(state);
  }

}

Dropbox.VERSION = packageJson.version;
module.exports = Dropbox;